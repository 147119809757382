import React from "react";
import { StaticQuery, graphql } from "gatsby";

const HomePage = () => (
  <StaticQuery
    query={graphql`
      query HomePage {
        contentfulHomePage {
          mainTitle
          mainSubTitle
          spaceId
          projects {
            categories {
              Cypax
            }
        }
  }
      }
    `}
    render={({
      contentfulHomePage: {
        mainTitle,
        mainSubTitle,
        
      }
    }) => (
      <>
        <h1>{mainTitle}</h1>
        <small>
          {mainSubTitle}
        </small>
      </>
    )}
  />
);

export default HomePage;